import React, { useState, useRef } from 'react';
import classNames from 'classnames';
import { isMobile, IOSKeyboardFix, isIphoneX, isAndroid } from '@/utils/helper';
import { REQUEST_PREFIX } from '@/utils/constant';

const Index = () => {
  const _isMobile = isMobile();
  const _isAndroid = isAndroid();
  const styles = require(`./${_isMobile ? 'm_' : ''}style.module.scss`);
  const PageTitle = require(`../../assets/${_isMobile ? 'm' : 'pc'}_supplier_title.png`);
  // 供应商属性选项
  const kindOpts = ['品牌方', '代理方', '工厂'];
  // 经营类目
  const typeOpts = [
    '生活家电',
    '电脑、配件',
    '手机数码',
    '摄影、摄像',
    '办公网络',
    '电子教育',
    '厨卫电器',
    '个护、健康电器',
    '影音视听',
    '手表闹钟',
    '母婴玩具',
    ' 食品保健',
    '外设、附件',
    '个护化妆',
    '汽车用品',
  ];

  const [companyName, setCompanyName] = useState(''); // 公司名称
  const [product, setProduct] = useState(''); //产品名称
  const [kind, setKind] = useState(''); // 供应商属性
  const [name, setName] = useState(''); // 联系人姓名
  const [type, setType] = useState(''); //经营类目
  const [wx, setWx] = useState(''); // 联系人微信
  const [brand, setBrand] = useState(''); // 品牌
  const [identity, setIdentity] = useState(''); // 联系人职务
  const [iosKeyboardFixTimer, setIosKeyboardFixTimer] = useState();
  const [androidKeyboardFixTimer, setAndroidKeyboardFixTimer] = useState();

  const form = [
    {
      label: '公司名称',
      placeholder: '请填写您的公司全称与营业执照一致',
      value: companyName,
      isSelector: false,
      isNecessary: true,
      onChangeEvent: (value) => setCompanyName(value),
    },
    {
      label: '供应商属性',
      placeholder: '请选择',
      value: kind,
      isSelector: true,
      options: kindOpts,
      isNecessary: true,
      onChangeEvent: (value) => setKind(value),
    },
    {
      label: '经营类目',
      placeholder: '请选择',
      value: type,
      isSelector: true,
      options: typeOpts,
      isNecessary: true,
      onChangeEvent: (value) => setType(value),
    },
    {
      label: '品牌',
      placeholder: '请输入品牌信息',
      value: brand,
      isSelector: false,
      isNecessary: true,
      onChangeEvent: (value) => setBrand(value),
    },
    {
      label: '产品名称',
      placeholder: '请输入产品信息',
      value: product,
      isSelector: false,
      isNecessary: true,
      onChangeEvent: (value) => setProduct(value),
    },
    {
      label: '联系人',
      placeholder: '请输入联系人姓名',
      value: name,
      isSelector: false,
      isNecessary: true,
      onChangeEvent: (value) => setName(value),
    },
    {
      label: '联系人微信',
      placeholder: '请输入联系人微信',
      value: wx,
      isSelector: false,
      isNecessary: true,
      onChangeEvent: (value) => setWx(value),
    },
    {
      label: '联系人职务',
      placeholder: '请输入联系人职务',
      value: identity,
      isSelector: false,
      isNecessary: true,
      onChangeEvent: (value) => setIdentity(value),
    },
  ];

  const mainRef = useRef();

  const onSubmit = () => {
    const params = {
      companyName,
      product,
      kind,
      name,
      type,
      wx,
      brand,
      identity,
    };

    for (const item of form) {
      if (item.isNecessary && item.value.trim() === '') {
        if (item.isSelector && item.value === item.placeholder) {
          return alert('请填写完整信息');
        }
        return alert('请填写完整信息');
      }
    }

    fetch(`${REQUEST_PREFIX}/c/mail/merchant`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      body: JSON.stringify(params),
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          return Promise.reject('提交资料失败，请重试');
        }
      })
      .then((data) => {
        alert('提交资料成功');
      })
      .catch((e) => alert(e));
  };

  const handleInputFocus = (e) => {
    // console.log(mainRef);
    if (_isAndroid) {
      window.addEventListener('resize', () => {
        setAndroidKeyboardFixTimer(
          setTimeout(() => {
            document.activeElement.scrollIntoViewIfNeeded();
          }, 0)
        );
      });
    } else {
      clearTimeout(iosKeyboardFixTimer);
    }
  };

  const handleInputBlur = () => {
    if (_isAndroid) {
      clearTimeout(androidKeyboardFixTimer);
    } else {
      setIosKeyboardFixTimer(
        setTimeout(() => {
          IOSKeyboardFix();
        }, 200)
      );
    }
  };

  return (
    <div className={classNames(_isMobile ? styles.m_page : styles.pc_page, styles.bg)}>
      <div className={styles.top_title_block}>
        <img src={PageTitle} alt="供应商入住" className={styles.title} />
      </div>
      <div
        ref={mainRef}
        className={styles.main}
        style={isIphoneX && { height: '100vh - 0.63rem - 0.35rem' }}
      >
        <div className={styles.form}>
          {form.map((item, index) => (
            <div key={index} className={styles.item_block}>
              <div className={styles.item_title_block}>
                {item.isNecessary && <span className={styles.star}>*</span>}
                <span className={styles.item_title}>{item.label}</span>
              </div>
              {item.isSelector ? (
                <div className={styles.select}>
                  <select onChange={(e) => item.onChangeEvent(e.target.value)}>
                    <option value={item.placeholder} className={styles.placeholder}>
                      {item.placeholder}
                    </option>
                    {item.options.map((opt, index) => (
                      <option key={index} value={opt}>
                        {opt}
                      </option>
                    ))}
                  </select>
                </div>
              ) : (
                <input
                  className={styles.input}
                  placeholder={item.placeholder}
                  defaultValue={item.value}
                  onChange={(e) => item.onChangeEvent(e.target.value)}
                  onFocus={(e) => handleInputFocus(e)}
                  onBlur={handleInputBlur}
                />
              )}
            </div>
          ))}
        </div>
        <div className={styles.submit_btn} onClick={onSubmit}>
          提交资料
        </div>
        <div className={styles.keep_record}>
          <span
            style={{
              cursor: 'pointer',
              color: '#007aff',
            }}
            onClick={() => {
              window.open('https://beian.miit.gov.cn/#/');
            }}
          >
            ICP主体备案号：蜀ICP备20024533号
          </span>
          <span>主办单位名称：成都音线网络科技有限公司</span>
        </div>
      </div>
    </div>
  );
};

export default Index;
