import React, { useState } from 'react';
import Swiper from 'react-id-swiper';
import 'swiper/css/swiper.css';

import styles from './style.module.scss';

import Page1 from './components/Page1';
import Page2 from './components/Page2';
import PaginationMask from './components/PaginationMask';
import AboutUs from './components/AboutUs';
import Supplier from '@/components/Supplier';

const Index = () => {
  const pages = [
    { hash: 'p1', component: <Page1 /> },
    { hash: 'p2', component: <Page2 /> },
    { hash: 'p3', component: <AboutUs /> },
    { hash: 'p4', component: <Supplier /> },
  ];

  const [mySwiper, setMySwiper] = useState(null);
  const [isShowBtnPrev, setIsShowBtnPrev] = useState(false);
  const [isShowBtnNext, setIsShowBtnNext] = useState(true);

  const swiperParams = {
    // 设置散列导航选项，或true使用默认值。为每个slide增加散列导航（有点像锚链接）。
    hashNavigation: {
      replaceState: true,
    },
    wrapperClass: styles.swiper_wrapper,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    renderPrevButton: () =>
      isShowBtnPrev && <PaginationMask type="prev" onClick={() => handlePageTurning('prev')} />,
    renderNextButton: () =>
      isShowBtnNext && <PaginationMask type="next" onClick={() => handlePageTurning('next')} />,
    getSwiper: obj => {
      if (!mySwiper) {
        setMySwiper({
          swiperRef: obj,
        });
      }
    },
    on: {
      slideChange: function() {
        handleChangeSwiper(this.realIndex);
      },
    },
  };

  const handleChangeSwiper = activeIndex => {
    let _isShowPrev = activeIndex !== 0;
    let _isShowNext = activeIndex !== pages.length - 1;
    setIsShowBtnPrev(_isShowPrev);
    setIsShowBtnNext(_isShowNext);
  };

  const handlePageTurning = type => {
    const _swiperRef = mySwiper.swiperRef;
    if (type === 'prev') {
      _swiperRef.slidePrev();
    } else {
      _swiperRef.slideNext();
    }
  };

  return (
    <Swiper {...swiperParams}>
      {pages.map((item, index) => (
        <div key={index} data-hash={item.hash}>
          {item.component}
        </div>
      ))}
    </Swiper>
  );
};

export default Index;
