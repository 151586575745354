import React from 'react';
import classNames from 'classnames';
import { DOWNLOAD_PAGE } from '@/utils/constant';
import { navigateTo } from '@/utils/helper';

import styles from './style.module.scss';

import Logo from '../../assets/logo.png';
import BgRight from '../../assets/page_1_bg_right.png';

const Index = () => {
  return (
    <div className={classNames(styles.pc_page, styles.bg)}>
      <img src={Logo} className={styles.logo} alt="logo" />
      <div className={styles.main}>
        <h1 className={styles.top_title}>大数据比价平台</h1>
        <h2 className={styles.sub_title}>汇集全网全平台价格数据</h2>
        <p className={styles.desc}>哪里买更划算/一搜便知</p>
        <div className={styles.bar}></div>
        <div className={styles.download_btn} onClick={() => navigateTo(DOWNLOAD_PAGE)}>
          立即下载APP
        </div>
      </div>
      <img src={BgRight} className={styles.bg_right} alt="bg_right" />
    </div>
  );
};

export default Index;
