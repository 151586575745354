import React from 'react';
import classNames from 'classnames';
import { CONSUMER_EMAIL, CONSUMER_HOTLINE } from '@/utils/constant';

import styles from './style.module.scss';

import BgRight from '../../assets/about_us_bg_right.png';
import mpQRCode from '@/assets/pc_wx_xcx_qrcode.png';
import kefuQRCode from '@/assets/pc_wx_consumer_service_qrcode.png';

const Index = () => {
  // 发展历程
  const timeline = ['2019年10月：趣比比APP上线', '2019年11月：趣比比上线首月GMV突破1000万'];
  // 企业文化
  const culture = [
    '使命：让购物更加简单透明。',
    '愿景：为提高国民素质生活。',
    '价值观：创新 赋能 共赢',
  ];

  return (
    <div className={classNames(styles.pc_page, styles.bg)}>
      <article className={styles.main}>
        <section className={styles.content}>
          <h2 className={styles.title}>发展历程</h2>
          <ul className={styles.text}>
            {timeline.map((item, idx) => (
              <li key={idx}>{item}</li>
            ))}
          </ul>
          <h2 className={styles.title}>企业文化</h2>
          <ul className={styles.text}>
            {culture.map((item, idx) => (
              <li key={idx}>{item}</li>
            ))}
          </ul>
        </section>
        <section className={styles.qr_codes}>
          <figure>
            <img src={mpQRCode} className={styles.img} alt="小程序二维码" />
            <figcaption className={styles.title}>小程序二维码</figcaption>
          </figure>
          <figure>
            <img src={kefuQRCode} className={styles.img} alt="客服微信" />
            <figcaption className={styles.title}>客服微信</figcaption>
          </figure>
        </section>
        <section className={styles.contact}>
          <div className={styles.group}>
            <span className={styles.title}>客服电话：</span>
            <span className={styles.content}>{CONSUMER_HOTLINE}</span>
          </div>
          <div className={styles.group}>
            <span className={styles.title}>客服邮箱：</span>
            <span className={styles.content}>{CONSUMER_EMAIL}</span>
          </div>
        </section>
      </article>
      <img src={BgRight} className={styles.bg_right} alt="bg_right" />
    </div>
  );
};

export default Index;
