// 请求域名前缀
export const REQUEST_PREFIX = 'https://m.aifenglife.com/api';
// export const REQUEST_PREFIX = 'https://aifeng.jiasuyunkeji.com/api';

// APP下载链接
export const DOWNLOAD_PAGE =
  'https://android.myapp.com/myapp/detail.htm?apkName=com.aifenglife.mainapp&ADTAG=mobile';

// 客服电话
export const CONSUMER_HOTLINE = '0755-26925436';

// 客服邮箱
export const CONSUMER_EMAIL = 'aifenglife@163.com';
